import React, { useState } from 'react';

function ProductRequirementTextArea({ handleFormChange }) {
    const [charCount, setCharCount] = useState(0);

    const handleTextAreaChange = (event) => {
        const { value } = event.target;

        // Update the state with the character count
        setCharCount(value.length);

        // Call the parent component's handleFormChange function
        handleFormChange(event);
    };
    return (
        <div className="col-span-full">
            <label htmlFor="document" className="block text-sm font-medium leading-6 text-gray-900">
                Enter your product requirement document here
            </label>
            <div className="mt-2">
                <textarea
                    id="document"
                    name="document"
                    rows={3}
                    className="max-h-96 resize-none min-[1290px]:h-72 h-48 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 py-1.5 text-sm leading-6"
                    defaultValue={''}
                    placeholder="Only include most essential parts. This will help improve clarity and conciseness.&#10;"
                    onChange={handleTextAreaChange}
                    maxLength={1500}
                />
                <div className="flex mt-1 items-center justify-end pointer-events-none">
                    <p className="text-xs font-medium text-gray-500">{charCount} / {1500}</p>
                </div>
            </div>
        </div>
    );
}

export default ProductRequirementTextArea;