import React from 'react';

function LanguageAndToneSelectors({ handleFormChange }) {
    return (
        <div className="flex mb-6">
            {/* Language selector */}
            <div className="mr-8">
                <label htmlFor="language" className="block text-sm font-medium leading-6 text-gray-900">
                    Select language
                </label>
                <select
                    id="language"
                    name="language"
                    autoComplete="language"
                    onChange={handleFormChange}
                    className="mt-2 block w-36 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                    <option>🇺🇸 English</option>
                    <option>🇨🇳 Chinese</option>
                    <option>🇪🇸 Spanish</option>
                </select>
            </div>
            {/* Tone selector */}
            <div>
                <label htmlFor="tone" className="block text-sm font-medium leading-6 text-gray-900">
                    Select tone
                </label>
                <div>
                    <select
                        id="tone"
                        name="tone"
                        autoComplete="tone"
                        onChange={handleFormChange}
                        className="mt-2 block w-36 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-36 sm:text-sm sm:leading-6"
                    >
                        <option>Convincing</option>
                        <option>Formal</option>
                    </select>
                </div>
            </div>
        </div>
    );
}

export default LanguageAndToneSelectors;
