import { useState, useEffect, useRef } from 'react';
import { fetchEventSource } from '@microsoft/fetch-event-source';

import LanguageAndToneSelectors from './LanguageAndToneSelectors';
import FormatRadioButtons from './FormatRadioButtons';
import ProductRequirementTextArea from './ProductRequirementTextArea';
import ReleaseNoteOutput from './ReleaseNoteOutput';
import BackgroundBase64Image from './images/background'

function debounce(func, delay) {
    let timeoutId;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(context, args), delay);
    }
}

function useReleaseNoteGenerator() {
    const [isFormValid, setIsFormValid] = useState(false);
    const [isPrinting, setIsPrinting] = useState(false);
    const [message, setMessage] = useState('');

    return { isFormValid, setIsFormValid, isPrinting, setIsPrinting, message, setMessage };
}

function useMessagePrinting(message, setMessage, setIsPrinting) {
    const messagesRef = useRef([]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            let curMessage = messagesRef.current[0];
            if (curMessage === "[DONE]") {
                setIsPrinting(false);
                messagesRef.current.shift();
            } else if (curMessage === "[START]") {
                setIsPrinting(true);
                messagesRef.current.shift();
            } else {
                if (messagesRef.current.length > 0) {
                    let ele = messagesRef.current.shift();
                    setMessage((prevMessage) => prevMessage + ele);
                    document.getElementById('outputArea').scrollTop = document.getElementById('outputArea').scrollHeight;
                }
            }
        }, 100);

        return () => clearInterval(intervalId);
    }, []);

    return messagesRef;
}

async function generateReleaseNote(requestData, messagesRef, setIsPrinting) {
    const response = await fetchEventSource(
        'https://api.notyet.site/release-notes',
        // ||
        // 'https://releasenote.up.railway.app/release-notes',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
            onmessage(ev) {
                const data = JSON.parse(ev.data);
                const received = data.message;

                messagesRef.current.push(received);
                setIsPrinting(true);
            },
            onclose() {
                console.log("close");
            }
        });
}

function ReleaseNoteGenerator() {
    const { isFormValid, setIsFormValid, isPrinting, setIsPrinting, message, setMessage } = useReleaseNoteGenerator();
    const messagesRef = useMessagePrinting(message, setMessage, setIsPrinting);

    const handleFormChange = () => {
        const isLanguageSelected = document.getElementById('language').value !== '';
        const isToneSelected = document.getElementById('tone').value !== '';
        const isFormatSelected = document.getElementById('simple').checked || document.getElementById('detailed').checked;
        const isDocumentValid = document.getElementById('document').value.trim() !== '';
        setIsFormValid(isLanguageSelected && isToneSelected && isFormatSelected && isDocumentValid);
    };

    const handleGenerateReleaseNote = debounce(() => {
        document.getElementById('outputArea').value = '';
        setMessage((prevMessage) => '');

        const selectedLanguage = (document.getElementById('language').value).replace(/\p{Emoji}/gu, '').replace(/\s/g, '');
        const selectedTone = document.getElementById('tone').value;
        const userSelectedSimple = document.getElementById('simple').checked;
        const selectedFormat = userSelectedSimple ? "Simple" : "Detailed";
        const productDocument = document.getElementById('document').value;

        const requestData = {
            selectedLanguage,
            selectedTone,
            selectedFormat,
            productDocument
        };

        generateReleaseNote(requestData, messagesRef, setIsPrinting);
    }, 600);

    return (
        <div className='bg-cover justify-center items-center flex w-screen h-screen' style={{ backgroundImage: `url(${BackgroundBase64Image})` }}>
            <div className="flex min-[1290px]:w-[1200px] w-[1100px] shadow-[0_0_30px_-5px_rgba(0,0,0,0.55)] rounded-xl">
                <div className=" bg-gray-100 p-8 w-[500px] rounded-l-xl">
                    <LanguageAndToneSelectors handleFormChange={handleFormChange} />
                    <FormatRadioButtons handleFormChange={handleFormChange} />
                    <ProductRequirementTextArea handleFormChange={handleFormChange} />
                </div>
                <div className="bg-white p-8 min-[1290px]:w-[700px] w-[600px] rounded-r-xl">
                    <p className="text-base font-semibold leading-7 text-indigo-600 transition-transform duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 cursor-pointer">Breathe easy 🧖‍♀️ 🧖 🧖‍♂️ </p>
                    <h1 className="mt-2 mb-10 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl cursor-default">Automate Your Release Notes</h1>
                    <ReleaseNoteOutput message={message} />
                    <div class="flex gap-x-6 mt-6">
                        <button onClick={handleGenerateReleaseNote} disabled={!isFormValid || isPrinting} class="flex justify-center rounded-md bg-indigo-600 w-48 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ">Surprise me →</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReleaseNoteGenerator;

