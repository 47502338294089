import React from 'react';

function FormatRadioButtons({ handleFormChange }) {
    return (
        <div className="space-y-10 mb-6">
            <fieldset>
                <legend className="text-sm font-semibold leading-6 text-gray-900">Choose your desired format</legend>
                <div className="mt-2 space-y-6">
                    <div className="relative flex gap-x-3">
                        <div className="flex h-6 items-center">
                            <input
                                id="simple"
                                name="format"
                                type="radio"
                                onChange={handleFormChange}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                        </div>
                        <div className="text-sm leading-6">
                            <label htmlFor="simple" className="font-medium text-gray-900">
                                Simple
                            </label>
                            <p className="text-gray-500">e.g. Introducing the Private Friends, allowing users to share content with friends privately. Enjoy the privacy you deserve!</p>
                        </div>
                    </div>
                    <div className="relative flex gap-x-3">
                        <div className="flex h-6 items-center">
                            <input
                                id="detailed"
                                name="format"
                                type="radio"
                                onChange={handleFormChange}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                        </div>
                        <div className="text-sm leading-6">
                            <label htmlFor="detailed" className="font-medium text-gray-900">
                                Detailed
                            </label>
                            <p className="text-gray-500">e.g. We have released a new feature that allows users to create private friend lists.</p>
                            <p className="text-gray-500">• Create private friend lists to share content</p>
                            <p className="text-gray-500 hidden min-[1290px]:block">• Manage friend lists to add or remove friends as needed</p>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    );
}

export default FormatRadioButtons;