import React from 'react';

function ReleaseNoteOutput({ message }) {
    return (
        <textarea
            id='outputArea'
            disabled={true}
            className="text-xl resize-none leading-8 text-gray-800 block p-0 w-full min-[1290px]:h-[500px] h-[380px] border-0 cursor-text"
            placeholder='Hey there! Convert your product requirement document into release note with one click.&#10;'
            value={message}
        >
        </textarea>
    );
}

export default ReleaseNoteOutput;
