import { createBrowserRouter } from "react-router-dom";
import ReleaseNoteGeneratorPage from './release-note-generator/ReleaseNoteGeneratorPage';
import HomePage from './home/HomePage';
import CompanyPage from "./company/CompanyPage";
import BlogHowToWriteReleasePage from "./blog/BlogHowToWriteReleasePage";

const router = createBrowserRouter([
    {
        path: "/",
        Component: ReleaseNoteGeneratorPage
    },
    {
        path: "/home",
        Component: HomePage
    },
    {
        path: "/company",
        Component: CompanyPage
    },
    {
        path: "/blog",
        Component: BlogHowToWriteReleasePage
    }
]);

export default router;